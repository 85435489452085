import React, { useState } from 'react';
import { Link } from 'gatsby';
import logo from '../../images/logo.png';
import { donateLink, navLinks } from '../../data';
import { Container } from '../container';

const Header = ({ className }) => {
  const [open, setOpen] = useState(false);
  const onToggle = () => {
    setOpen(!open);
  };
  return (
    <header className={`${className} relative bg-green-50`}>
      <BaseMenu toggle={onToggle} />
      <MobileMenu open={open} toggle={onToggle} className={`${open ? 'block' : 'hidden'} md:hidden`} />
    </header>
  );
};

const MobileMenu = ({ open, toggle, className }) => (
  /* Mobile menu, show/hide based on mobile menu state.

      Entering: "duration-200 ease-out"
        From: "opacity-0 scale-95"
        To: "opacity-100 scale-100"
      Leaving: "duration-100 ease-in"
        From: "opacity-100 scale-100"
        To: "opacity-0 scale-95"*/

  <div className={`absolute top-0 inset-x-0 p-2 transition transform origin-top-right ${className}`}>
    <div className="rounded-lg shadow-lg">
      <div className="rounded-lg ring-1 ring-black ring-opacity-5 bg-green-50 divide-y-2 divide-gray-50">
        <div className="pt-5 pb-6 px-5 space-y-6">
          <div className="flex items-center justify-between">
            <div>
              <img className="h-8 w-auto" src={logo} alt="Mererue" />
            </div>
            <div className="-mr-2">
              <BurgerMenu open={open} toggle={toggle} />
            </div>
          </div>
          <div>
            <nav className="grid gap-x-8 gap-y-6">
              {navLinks.map((link, index) => (
                <Link
                  key={index}
                  to={link.to}
                  className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150"
                >
                  <svg className="flex-shrink-0 h-6 w-6 text-green-600" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                    {link.svgPath}
                  </svg>
                  <div className="text-base font-medium text-gray-900">{link.label}</div>
                </Link>
              ))}
            </nav>
          </div>
        </div>
        <div className="py-6 px-5 space-y-6">
          <span className="w-full flex rounded-md shadow-sm">
            <a
              href={donateLink.to}
              className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:ring focus:ring-green-500 active:bg-green-700 transition ease-in-out duration-150"
            >
              {donateLink.label}
            </a>
          </span>
        </div>
      </div>
    </div>
  </div>
);

const BaseMenu = ({ open, toggle }) => (
  <Container className="px-4 sm:px-6" maxWidth="7xl">
    <div className="flex justify-between items-center border-b-2 border-green-100 py-6 md:justify-start md:space-x-10">
      <div className="lg:w-0 lg:flex-1">
        <Link to="/" className="inline-flex">
          <img className="h-8 w-auto sm:h-10" src={logo} alt="Mererue" />
        </Link>
      </div>
      <div className="-mr-2 -my-2 md:hidden">
        <BurgerMenu open={open} toggle={toggle} />
      </div>
      <nav className="hidden md:flex space-x-10">
        {navLinks.map((link, index) => (
          <Link
            to={link.to}
            key={index}
            className="text-base font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150"
          >
            {link.label}
          </Link>
        ))}
      </nav>
      <div className="hidden md:flex items-center justify-end space-x-8 md:flex-1 lg:w-0">
        <span className="inline-flex rounded-md shadow-sm">
          <a
            href={donateLink.to}
            className="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:ring focus:ring-green-500 active:bg-green-700 transition"
          >
            {donateLink.label}
          </a>
        </span>
      </div>
    </div>
  </Container>
);

const BurgerMenu = ({ open, toggle }) => (
  <button
    onClick={toggle}
    className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
  >
    <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
      {open ? (
        <path className="inline-flex" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
      ) : (
        <path className="inline-flex" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
      )}
    </svg>
  </button>
);

export default Header;
