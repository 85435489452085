import fondatriceImg from '../images/about/fondatrice.jpg';
import directriceImg from '../images/about/directrice.jpg';

export const anneMarie = {
  name:'Anne Marie Sow',
  title: 'Fondatrice MERERUE, Présidente AFDEAA',
  email: 'mererue@yahoo.fr',
  telephone: '+221 77 633 47 31',
  imageSrc: fondatriceImg
}

export const issaSow = {
  name:'Ndeye Issa Wade',
  title: 'Directrice MERERUE, Présidente Régionale AFDEAA',
  telephone: '+221 76 131 65 13',
  imageSrc: directriceImg
}

