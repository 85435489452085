import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';

import imageUrl from '../../images/social-card.png';
import Header from '../header/header';
import Footer from '../footer/footer';

const siteMeta = [
  { name: 'description', content: 'Site web de MERERUE' },
  { name: 'keywords', content: 'social, handicap, enfant' },
  // Open Graph data
  { property: 'og:title', content: 'MERERUE' },
  { property: 'og:site_name', content: 'MERERUE' },
  { property: 'og:type', content: 'website' },
  { property: 'og:url', content: 'https://www.mererue.com/' },
  { property: 'og:description', content: `Encadrement et réinsertion des enfants de la rue.` },
  { property: 'og:image', content: imageUrl },
  // Twitter Card data
  { property: 'twitter:card', content: 'summary_large_image' },
  { property: 'twitter:title', content: 'MERERUE' },
  { property: 'twitter:site', content: '@ahasall' },
  { property: 'twitter:creator', content: '@ahasall' },
  { property: 'twitter:url', content: 'https://www.mererue.com/' },
  { property: 'twitter:description', content: `Encadrement et réinsertion des enfants de la rue.` },
  { property: 'twitter:image:src', content: imageUrl },
];

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <>
        <Helmet title={data.site.siteMetadata.title} meta={siteMeta}>
          <body className="bg-green-50 font-sans antialiased text-gray-800" />
        </Helmet>

        <div className="min-h-screen flex flex-col">
          <Header className="sticky top-0 z-10" />
          <main className="flex-1">{children}</main>
          <Footer />
        </div>
      </>
    )}
  />
);

export default Layout;
