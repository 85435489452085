import React from 'react';
import { Container } from '../container';
import { Text } from '../text';
import { MailSvgIcon } from '../../icons/mail';
import { PhoneSvgIcon } from '../../icons/phone';
import { anneMarie, issaSow } from '../../data/people';

const Copyright = ({ className }) => (
  <div className={`${className} border-t border-green-200 pt-8 text-center`}>
    <Text size="md">&copy; {new Date().getFullYear()} MERERUE. Tous droits réservés.</Text>
  </div>
);

const Footer = () => (
  <footer className="bg-white border-t-2 border-green-500">
    <Container maxWidth="7xl" className="px-4 sm:px-6 lg:px-8 py-6 lg:py-8">
      <div className="grid grid-cols-1 gap-8 md:grid-cols-2 py-6 lg:py-8">
        <div>
          <div className="space-y-2">
            <Text size="xl" weight="bold">
              À propos
            </Text>
            <p>
              MERERUE oeuvre dans l'encadrement et la réinsertion des enfants vulnérables. MERERUE les inscrit à
              l’état civil, les éduque et leur assure un suivi sanitaire et nutritionnel.
            </p>
          </div>
          <div className="space-y-2">
            <div>Adresse</div>
            <Text size="xl" weight="bold">
              Adresse
            </Text>
            <p>Sor Daga, près de la gare routière de Saint-Louis</p>
          </div>
        </div>

        <div className="space-y-2">
          <Text size="xl" weight="bold">
            Nous Contacter
          </Text>
          <div className="space-y-4">
            <div className="space-y-2">
              <Text size="lg" weight="medium">
                <span>{anneMarie.name}</span>, Fondatrice MERERUE
              </Text>
              <div className="space-y-1">
                <a className="block" href={`mailto:${anneMarie.email}`}>
                  <MailSvgIcon className="h-6 w-6 inline-block text-green-500" />
                  <span className="ml-4">{anneMarie.email}</span>
                </a>
                <a className="block" href={`tel:${anneMarie.telephone}`}>
                  <PhoneSvgIcon className="h-6 w-6 inline-block text-green-500" />
                  <span className="ml-4">{anneMarie.telephone}</span>
                </a>
              </div>
            </div>
            <div className="space-y-2">
              <Text size="lg" weight="medium">
                <span>{issaSow.name}</span>, Directrice MERERUE
              </Text>
              <div className="space-y-1">
                <a className="block" href={`tel:${issaSow.telephone}`}>
                  <PhoneSvgIcon className="h-6 w-6 inline-block text-green-500" />
                  <span className="ml-4">{issaSow.telephone}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Copyright />
    </Container>
  </footer>
);

export default Footer;
